import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Row, Column } from '../ui/layout/Grid'
import LinkOut from '../ui/forms/LinkOut'

import twitter from '../../images/assets/social_media/icons/twitter.svg'
import github from '../../images/assets/social_media/icons/github.svg'
import linkedin from '../../images/assets/social_media/icons/linkedin.svg'
import angel from '../../images/assets/social_media/icons/angel.svg'

const setIcon = icon => {
  switch (icon) {
  case 'twitter':
    return css`
        background-image: url(${ twitter });
      `
  case 'github':
    return css`
        background-image: url(${ github });
      `
  case 'linkedin':
    return css`
        background-image: url(${ linkedin });
      `
  case 'angel':
    return css`
        background-image: url(${ angel });
      `
  }
}

const ContactStyle = styled(Row)`
  height: 100%;
  border-top: 1px solid ${ props => props.theme.color.grey[1] };
  flex: 1;
`

const SocialColumn = styled(Column)`
  max-width: 100% !important;
  padding: 0.5em;

  &:not(:last-child) {
    border-right: 1px solid ${ props => props.theme.color.grey[1] };
  }
`

const SocialLink = styled(LinkOut)`
  display: flex;
  position: relative;
  font-size: 0;
  width: 100%;
  align-items: center;
  justify-items: center;
  flex-direction: column;

  :hover {
    opacity: 0.6;
    transition: opacity 0.25s ${ props => props.theme.animation };
  }

  &::before {
    content: "";
    display: inline-block;
    ${ props => setIcon(props.socialType) };
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
  }
`

const Contact = ({ details }) => (
  <ContactStyle
    mb="0"
    mt="0"
  >
    {Object.keys(details).map(
      (social, idx) =>
        details[social] && (
          <SocialColumn
            key={idx}
          >
            <SocialLink
              to={details[social]}
              socialType={social}
            >
              {social}
            </SocialLink>
          </SocialColumn>
        )
    )}
  </ContactStyle>
)

Contact.propTypes = {
  details: PropTypes.shape({
    twitter: PropTypes.string,
    github: PropTypes.string,
    linkedin: PropTypes.string,
    angel: PropTypes.string
  }).isRequired
}
export default Contact
