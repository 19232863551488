import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const formatName = arr => {
  const parseName = arr.split('_')
  const capName = parseName
    .map(name => name.charAt(0).toUpperCase() + name.slice(1))
    .join(' ')
  return capName
}

const AvatarStyle = styled.div`
  max-width: 128px;
  max-height: 128px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1.2em;
  margin-top: 1.2em;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 128px;
    height: 128px;
    background-color: ${ props => props.theme.color.grey[0] };
    border-radius: 200px;
  }
`

const Avatar = props => {
  return (
    <StaticQuery
      query={avatars}
      render={({ avatars }) => {
        const pic = avatars.edges.filter(
          avatar => props.name === formatName(avatar.node.name)
        )
        return (
          <AvatarStyle>
            <Img
              fluid={pic[0].node.childImageSharp.fluid}
              fadeIn={true}
            />
          </AvatarStyle>
        )
      }}
    />
  )
}

export const avatars = graphql`
  query {
    avatars: allFile(filter: { sourceInstanceName: { eq: "team" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 128) {
              ...GatsbyImageSharpFluid
            }
          }
          id
          name
        }
      }
    }
  }
`

export default Avatar
