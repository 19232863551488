import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Tabs from '../ui/layout/Tabs'
import Avatar from './Avatar'
import Contact from './Contact'
import { Row, Column } from '../ui/layout/Grid'
import { Card, CardTitle, CardSubtitle } from '../ui/layout/Cards'

function renderTeam (arr, role) {
  return arr.map(
    member =>
      member.node.active &&
      member.node.category === role && (
        <Column key={member.node.id}>
          <Card>
            <Avatar name={member.node.name} />
            <CardTitle>{member.node.name}</CardTitle>
            <CardSubtitle>{member.node.title}</CardSubtitle>
            <Contact details={member.node.contact} />
          </Card>
        </Column>
      )
  )
}

const Team = () => {
  const { team } = useStaticQuery(teamQuery)
  return (
    <Tabs>
      <div label="Team">
        <Row grid={1 / 4}>
          {renderTeam(team.edges, 'Team')}
        </Row>
      </div>
      <div label="Advisors">
        <Row grid={1 / 4}>
          {renderTeam(team.edges, 'Advisor')}
        </Row>
      </div>
    </Tabs>
  )
}

export const teamQuery = graphql`
  query {
    team: allTeamJson {
      edges {
        node {
          id
          active
          name
          category
          title
          contact {
            linkedin
            angel
            twitter
            github
          }
        }
      }
    }
  }
`
export default Team
