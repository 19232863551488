import React from 'react'

// Components
import { Container, Row, Column, Inner } from '../components/ui/layout/Grid'
import Button from '../components/ui/forms/Buttons'
import PageIntro from '../components/Hero/PageIntro'
import { ContentBox, ContentBoxIcon } from '../components/ui/layout/ContentBox'
import Image from '../components/ui/layout/Image'
import Team from '../components/Team'
import Newsletter from '../components/Newsletter'
import Seo from '../components/seo'

// Assets
import signalIcon from '../images/assets/icons/trading_signal_icon.svg'
import algoIcon from '../images/assets/icons/algorithmic_trading_icon.svg'
import instiIcon from '../images/assets/icons/institutional_trading_icon.svg'
import signal from '../images/assets/signal.svg'
import isometric from '../images/assets/isometric.svg'
import skyline from '../images/assets/skyline.svg'

const About = () => (
  <>
    <Seo title="About Investabit" />
    <PageIntro
      title="About Us"
      topTitle="What we are and who we are"
      subtitle="The Investabit team is comprised of a multitude of strengths and disciplines culminating in an effort to build an advanced, Proprietary Data Aggregation, in order to provide an interface for AI and ML driven trading solutions."
      cta={{
        to: 'about/#features',
        label: 'See Features',
        style: 'justify-content: center;'
      }}
      css={`
        text-align: center;
      `}
    />
    <Container id="features">
      <Row mt="5em">
        <Column
          dir="row"
          justify="center"
        >
          <h2>Core Features</h2>
        </Column>
      </Row>
      <Row align="center">
        <Column>
          <ContentBox
            bg="#56DBC1"
            bgPattern={true}
          >
            <ContentBoxIcon
              icon={signalIcon}
              outside
            />
            <h2>Data API</h2>
            <p>
              Real time, live low latency data and FIX connections including websockets, perfect for trade data and order books.
            </p>
            <Button
              appearance="secondary"
              css="margin-top:10px;"
              to="/products/data-api"
            >Investabit Data API</Button>
          </ContentBox>
        </Column>
        <Column>
          <Inner>
            <Image
              src={signal}
              alt="Trade signals"
              padding="2.5em"
            />
          </Inner>
        </Column>
      </Row>
      <Row align="center">
        <Column>
          <Inner>
            <Image
              src={isometric}
              alt="Algorithmic Trading"
              padding="2.5em"
            />
          </Inner>
        </Column>
        <Column>
          <ContentBox
            bg="#FFE9BA"
            bgPattern={true}
          >
            <ContentBoxIcon
              icon={algoIcon}
              outside
            />
            <h2>Data Sales</h2>
            <p>
              Downloadable historic and ongoing data from nearly every exchange and cryptocurrency all in one place at a price no one can beat.
            </p>
            <Button
              css="margin-top:10px;"
              appearance="secondary"
              to="/products/data-sales"
            >Investabit Data Sales</Button>
          </ContentBox>
        </Column>
      </Row>
      <Row align="center">
        <Column>
          <ContentBox
            bg="#8dd1ff"
            bgPattern={true}
          >
            <ContentBoxIcon
              icon={instiIcon}
              outside
            />
            <h2>Custom Solutions</h2>
            <p>Have something a bit more complex or need a bit of hand holding? We’re able to support and integrate with your custom needs. Send us an email to get started.</p>
            <Button
              as="a"
              appearance="link"
              href="mailto:contact@investabit.com"
              style={{ marginTop: '1.2em' }}
            >
              Send us an email
            </Button>
          </ContentBox>
        </Column>
        <Column>
          <Inner>
            <Image
              src={skyline}
              alt="Institutional Trading"
              padding="2.5em"
            />
          </Inner>
        </Column>
      </Row>
      <Row>
        <Column
          dir="row"
          justify="center"
        >
          <h2>Meet the Team</h2>
        </Column>
      </Row>
      <Team />
      <Newsletter />
    </Container>
  </>
)

export default About
